import React from "react"

import { DrinkMenu, Layout, SEO } from "src/components"
import { PATHS } from "src/constants"

const DrinkMenuPage = props => {
  const { location } = props
  const activeNavLink = PATHS[location.pathname]

  return (
    <Layout activeNavLink={activeNavLink}>
      <SEO title="New Golden Dragon | Drink Menu" />
      <DrinkMenu />
    </Layout>
  )
}

export default DrinkMenuPage
